import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import CssBaseline from '@material-ui/core/CssBaseline';
import styled from 'styled-components';
import { ThemeProvider } from '@material-ui/styles';

import Header from './header';
import theme from '../theme';
import { Container } from '@material-ui/core';

const Root = styled.div`
  display: grid;
  grid-template-areas: 'header' 'content' 'footer';
  grid-template-rows: auto 1fr auto;
  height: 100vh;
`;

const StyledHeader = styled(Header)`
  grid-area: header;
`;

const StyledContainer = styled(Container)`
  display: flex;
  grid-area: content;
  flex-direction: column;
`;

const StyledFooter = styled.footer`
  grid-area: footer;
`;

export type LayoutProps = {
  showHeader: boolean;
};

const Layout: FC<LayoutProps> = ({ children, showHeader }) => {
  const data = useStaticQuery<{
    site: { siteMetadata: { title: string } };
  }>(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={theme}>
      <Root>
        <CssBaseline />
        {showHeader && (
          <StyledHeader siteTitle={data.site.siteMetadata.title} />
        )}
        <StyledContainer
          component={'main'}
          maxWidth={'xl'}
          style={{
            maxWidth: showHeader ? undefined : 'unset',
            padding: showHeader ? undefined : 0,
          }}
        >
          {children}
        </StyledContainer>
        <StyledFooter />
      </Root>
    </ThemeProvider>
  );
};

export default Layout;
