import React, { FC } from 'react';
import { AppBar } from '@material-ui/core';

type HeaderProps = {
  siteTitle: string;
};

const Header: FC<HeaderProps> = ({ siteTitle }) => (
  <AppBar position={'relative'}>
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>{siteTitle}</h1>
    </div>
  </AppBar>
);

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
